<template>
  <v-app id="inspire">
    <v-content >
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
  data() {
    return {
      expandOnHover: false,
    }
  }
}
</script>
